import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from "@angular/router";
import * as firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  returnurl: string;
  redirectURL: any;
  getemailbyphn: any;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })

    
    let params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
        this.redirectURL = params['redirectURL'];
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }
  // Sign in with email/password
  SignIn(email, password) {
    var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    //if phone number
    if(phoneRegex.test(email)){
     console.log(email)
      this.afs.collection("users", ref => ref.where("phone", "==" ,email)).get().toPromise().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            this.getemailbyphn=doc.data().email
            console.log(this.getemailbyphn)
                  
      return this.afAuth.auth.signInWithEmailAndPassword(this.getemailbyphn, password)
      .then((result) => {
        this.ngZone.run(() => {

          
          if (this.redirectURL) {        
            this.router.navigateByUrl(this.redirectURL,)
                .catch(() => this.router.navigate(['/home']))
        } else {
        
            this.router.navigate(['/home'])
        }


        });
        this.SetUserData(result.user);
      }).catch((error) => {
        //window.alert(error.message)
        //this.openSnackBar(error.message,"OK");
      })

          })
        }
      })

    }
    //if email
    else{
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {

          
          if (this.redirectURL) {        
            this.router.navigateByUrl(this.redirectURL,)
                .catch(() => this.router.navigate(['/home']))
        } else {
        
            this.router.navigate(['/home'])
        }


        });
        this.SetUserData(result.user);
      }).catch((error) => {
        //window.alert(error.message)
        //this.openSnackBar(error.message,"OK");
      })
    }
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['/verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {

    
    
    
    if (this.redirectURL) {        
        this.router.navigateByUrl(this.redirectURL,)
            .catch(() => this.router.navigate(['homepage']))
    } else {
    
        this.router.navigate(['homepage'])
    }


         })
      this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error)
    })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      phone:user.phoneNumber,
      displayName: 'MY ACCOUNT',
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/sign-in']);
    })
  }

}
