// 'use strict';

// export const sep='/';
// export const version: string="22.2.2";  


import { Injectable } from "@angular/core";

@Injectable()
// export class Globals{
//    companyname: string ="SENS";
//    address1L1: string="SPORTING SENS";
//    address1L2: string="2721-30, North Main Street,";
//    address1L3: string="Brindavanam,";
//    address1L4: string="Pudukkottai, 622001";
//    address2L1: string="SPORTING SENS";
//    address2L2: string="68, NGO Colony,";
//    address2L3: string="Machuvadi,";
//    address2L4: string="Pudukkottai, 622001";
//    GSTIN: string="33BZCPS3550H1ZF";
//    mobile1:string="+91 7373720204";
//    mobile2:string="+91 7373720205";
//    mobile3:string="+91 7373720206";
// }


export class Globals{
   companyname: string ="NISHA NIGHTIES";
   address1L1: string="NISHA NIGHTIES";
   address1L2: string="H-II-20, Pudukkottai Railway Station Rd,";
   address1L3: string="Poonga Nagar,";
   address1L4: string="Pudukkottai, 622003";
   address2L1: string="NISHA NIGHTIES";
   address2L2: string="H-II-20, Pudukkottai Railway Station Rd,";
   address2L3: string="Poonga Nagar,";
   address2L4: string="Pudukkottai, 622003";
   GSTIN: string="33BQSPS6489Q1ZE";
   mobile1:string="+91 9344823456";
}