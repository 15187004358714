import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, timestamp } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Globals} from '../../assets/shared/globals'

@Component({
  selector: 'app-allreviews',
  templateUrl: './allreviews.component.html',
  styleUrls: ['./allreviews.component.css']
})
export class AllreviewsComponent implements OnInit {

  ratingsreviewsary: any;
  ratingsreviewsarylength: any;

  currentcustid: string;
  currentprdkeyid: any;

  selectedproduct: any[]=[];

  constructor(public snackBar: MatSnackBar, public globals: Globals, public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private afs: AngularFirestore, private changeDetection: ChangeDetectorRef, public router: Router) {
 
this.afAuth.authState.subscribe(user=>{
  if(user){
  this.currentcustid=firebase.auth().currentUser.uid;
  }
})

   }
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }
  ngOnInit() {
    
    this.route.queryParamMap.subscribe((params: any) => {
      this.currentprdkeyid = String(JSON.parse(params.params.myprParam));
      console.log(this.currentprdkeyid)
      });  

    this.afs.collection('Products',ref => ref.where('KEYID', '==', this.currentprdkeyid))
    .get().toPromise()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.selectedproduct.push({'KEYID':doc.data().KEYID,
                              'bcc':doc.data().bcc,
                              'brand':doc.data().brand,
                              'category':doc.data().category,
                              'code':doc.data().code, 
                              'onlinerate':doc.data().onlinerate,                                    
                              'images':doc.data().images,
                              'color':doc.data().color,
                             'size':doc.data().size,
                             'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                             'totalstars':doc.data().totalstars ? doc.data().totalstars: 0
                           });
        });
    }).then(()=>{


    this.ratingsreviewsary =[];
    this.ratingsreviewsarylength=0;
    this.ratingsreviewsary=this.afs.collection("Ratingsreviews",ref=> ref.where('pid', '==', this.currentprdkeyid).limit(5)).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => a.payload.doc.data()))
      );
      this.ratingsreviewsary.subscribe(result =>{
        console.log(result.length);
        this.ratingsreviewsarylength=result.length;
      })
    })
  }
  productclicked(prdid){
    this.router.navigate(
      ['productdescription'], 
      {
        queryParams: {myprParam: JSON.stringify(prdid) } ,
        // queryParamsHandling: 'merge'
      });
  }
}
