import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AfterShip } from 'aftership';
import { MatTabGroup } from '@angular/material';
import {Globals} from '../../assets/shared/globals'

@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.css']
})


export class OrderdetailsComponent implements OnInit {
  @ViewChild('matgroup', {static: true})tabgroup: MatTabGroup;

  trackingdetails:any=[];

  currentorderedid: any;
  currentcustid: string;
  productdetailsary: any=[];
  paymentdetailsary: any=[];
  trackingdetailsary: any=[];
  userdetailsary: any=[];
  placedclass: string ="done process";
  shippedclass: string ="todo disabledli";
  outfordeliveryclass: string ="todo disabledli";
  deliveredclass: string ="todo disabledli";

  placeddisabled: boolean =false;
  shippeddisabled: boolean =true;
  outfordeliverydisabled: boolean =true;
  delivereddisabled: boolean =true;
  trackingdetailskeyid: string='';
  idindex: number=0;
  outfordelividindex:  number=0;
  delividindex:  number=0;

  constructor( public globals: Globals, public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private afs: AngularFirestore, private http:HttpClient, private route: ActivatedRoute, public router: Router) {
    this.route.queryParamMap.subscribe((params: any) => {
      this.currentorderedid = String(JSON.parse(params.params.myorParam));
      console.log(this.currentorderedid)
      });  
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }
  ngOnInit() {

    
    // const aftership = new AfterShip('d3bbe90a-3c68-4473-9bbd-66c325a1d37c');
    
    // aftership.tracking
    // .getTracking({
    //   slug: "professional-couriers",
    //   tracking_number: "TRZ101966429",
    // })
    // .then((result) => this.trackingdetails=(result.tracking))
    // .catch((e) => console.log(e));

    
    const aftership = new AfterShip('d3bbe90a-3c68-4473-9bbd-66c325a1d37c');
  
    this.productdetailsary=[];
      this.paymentdetailsary=[];
      this.trackingdetailsary=[];
      this.userdetailsary=[];

    this.afs.collection("Orders").doc(this.currentorderedid).collection("productdetails").get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.productdetailsary.push((doc.data()))
        })
      }
    })

    this.afs.collection("Orders").doc(this.currentorderedid).collection("paymentdetails").get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.paymentdetailsary.push((doc.data()))
        })
      }
    })
  
    this.afs.collection("Orders").doc(this.currentorderedid).collection("trackingdetails").get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.trackingdetailsary.push((doc.data()))
          if(doc.data().oid==this.currentorderedid){
          this.trackingdetailskeyid=doc.data().KEYID;
          console.log(this.trackingdetailskeyid)
          }
        })
        this.idindex=this.trackingdetailsary.findIndex(item=>item.KEYID === this.trackingdetailskeyid)
        if(this.trackingdetailsary[this.idindex].shipped ==''){this.trackingdetailsary[this.idindex].shipped = this.trackingdetailsary[this.idindex].expectedshipmentdate}

        this.outfordelividindex=this.trackingdetailsary.findIndex(item=>item.KEYID === 'outfordelivery')
        this.delividindex=this.trackingdetailsary.findIndex(item=>item.KEYID === 'delivered')
        console.log(this.idindex,this.outfordelividindex,this.delividindex)
        if(this.outfordelividindex!=-1){
          this.trackingdetailsary[this.idindex].outfordelivery=this.trackingdetailsary[this.outfordelividindex].outfordelivery
        }
        else if(this.outfordelividindex==-1){this.trackingdetailsary[this.idindex].outfordelivery=this.trackingdetailsary[this.idindex].expecteddeliverydate}
        if(this.delividindex!=-1){
          this.trackingdetailsary[this.idindex].delivered=this.trackingdetailsary[this.delividindex].delivered
        }
        else if(this.delividindex==-1){this.trackingdetailsary[this.idindex].delivered=this.trackingdetailsary[this.idindex].expecteddeliverydate}
      }
    })
    .then(()=>{
      console.log(this.trackingdetailsary[this.idindex].slug)
      aftership.tracking
   .getTracking({
     slug: this.trackingdetailsary[this.idindex].slug,
     tracking_number: this.trackingdetailsary[this.idindex].trackingnumber
   })
   .then((result) => {
     this.trackingdetails=(result.tracking)
console.log(this.trackingdetails)
     
     if (this.trackingdetailsary[this.idindex].placed != '' && this.trackingdetailsary[this.idindex].trackingnumber != '') {

      //if (this.trackingdetails['tag'] == 'InTransit') {
       this.tabgroup.selectedIndex = 1;
       this.placedclass = "done";
       this.shippedclass = "done process";
       this.outfordeliveryclass = "todo disabledli";
       this.deliveredclass = "todo disabledli";

       this.placeddisabled=false;
       this.shippeddisabled=false;
       this.outfordeliverydisabled=true;
       this.delivereddisabled=true;

      //}
       if (this.trackingdetails['tag'] == 'OutForDelivery') {

         this.tabgroup.selectedIndex = 2;
         this.placedclass = "done";
         this.shippedclass = "done";
         this.outfordeliveryclass = "done process";
         this.deliveredclass = "todo disabledli";

         this.placeddisabled=false;
         this.shippeddisabled=false;
         this.outfordeliverydisabled=false;
         this.delivereddisabled=true;

          if(this.trackingdetailsary[this.idindex].outfordelivery==this.trackingdetailsary[this.idindex].expecteddeliverydate && this.trackingdetails['shipment_delivery_date'] != null){
            this.afs.collection("Orders").doc(this.currentorderedid).collection("trackingdetails").doc("outfordelivery").set({
              'outfordelivery':new Date(this.trackingdetails['shipment_delivery_date'].toString()),
              'KEYID':'outfordelivery'
            },{'merge':true})
            this.trackingdetailsary[this.outfordelividindex].outfordelivery=this.trackingdetails['shipment_delivery_date'];
          }
       }

       if (this.trackingdetails['tag'] == 'Delivered') {

         this.tabgroup.selectedIndex = 3;
         this.placedclass = "done";
         this.shippedclass = "done";
         this.outfordeliveryclass = "done";
         this.deliveredclass = "done";

         this.placeddisabled=false;
         this.shippeddisabled=false;
         this.outfordeliverydisabled=false;
         this.delivereddisabled=false;

         if(this.trackingdetailsary[this.idindex].delivered==this.trackingdetailsary[this.idindex].expecteddeliverydate && this.trackingdetails['shipment_delivery_date'] != null){
          this.afs.collection("Orders").doc(this.currentorderedid).collection("trackingdetails").doc("delivered").set({
            'delivered':new Date(this.trackingdetails['shipment_delivery_date'].toString()),
            'KEYID':'delivered'
          },{'merge':true})
          this.trackingdetailsary[this.idindex].delivered=this.trackingdetails['shipment_delivery_date'];
         }
         if(this.trackingdetailsary[this.idindex].outfordelivery==this.trackingdetailsary[this.idindex].expecteddeliverydate && this.trackingdetails['shipment_delivery_date'] != null){
         this.afs.collection("Orders").doc(this.currentorderedid).collection("trackingdetails").doc("outfordelivery").set({
           'outfordelivery':new Date(this.trackingdetails['shipment_delivery_date'].toString()),
           'KEYID':'outfordelivery'
         },{'merge':true})
         this.trackingdetailsary[this.idindex].outfordelivery=this.trackingdetails['shipment_delivery_date'];
        }

       }

    }

    })
   .catch((e) => console.log(e));

     })
    this.afs.collection("Orders").doc(this.currentorderedid).collection("userdetails").get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.userdetailsary.push((doc.data()))
        })
      }
    })

    }
    productclicked(prdid){
      this.router.navigate(
        ['productdescription'], 
        {
          queryParams: {myprParam: JSON.stringify(prdid) } ,
          // queryParamsHandling: 'merge'
        });
    }
  }

