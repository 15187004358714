import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NgxStarsComponent } from 'ngx-stars';
import { Globals } from 'src/assets/shared/globals';

@Component({
  selector: 'app-ratingsandreviews',
  templateUrl: './ratingsandreviews.component.html',
  styleUrls: ['./ratingsandreviews.component.css']
})
export class RatingsandreviewsComponent implements OnInit {

  clickedprdid: any;
  currentcustid: string;
  selectedproduct: any[]=[];

  cucprating: number=0;
  cucpratingmid: any;
  isalreadyratedstatus: boolean;
  cptratedstars: any;
  cptratedby: any;

  @ViewChild(NgxStarsComponent, {static: false}) StarsComponent: NgxStarsComponent;
  rating: number=0;
  review: string='';
  reviewtitle: string='';
  orderedidary: any[];
  productdetailsary: any[];
  purchased: boolean;

  constructor(public snackBar: MatSnackBar, public afAuth: AngularFireAuth, public globals:Globals, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private afs: AngularFirestore, private changeDetection: ChangeDetectorRef, public router: Router) {
      // this.afAuth.authState.subscribe(user=>{
      // if(user){
      // this.currentcustid=firebase.auth().currentUser.uid;
      // }
      // else{
      //   this.router.navigate(
      //     ['sign-in'], 
      //     {
      //       queryParams: { redirectURL: this.router.url } 
      //     });
      // }
      // })
       this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      }
    })
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }
  ngOnInit() {

    // this.review='';
    // this.reviewtitle='';

    this.route.queryParamMap.subscribe((params: any) => {
      this.clickedprdid = String(JSON.parse(params.params.myprParam));
      console.log(this.clickedprdid)
      });  

      this.afs.collection('Products',ref => ref.where('KEYID', '==', this.clickedprdid))
      .get().toPromise()
      .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.selectedproduct.push({'KEYID':doc.data().KEYID,
                                'bcc':doc.data().bcc,
                                'brand':doc.data().brand,
                                'category':doc.data().category,
                                'code':doc.data().code, 
                                'onlinerate':doc.data().onlinerate,                                    
                                'images':doc.data().images,
                                'color':doc.data().color,
                               'size':doc.data().size,
                               'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                               'totalstars':doc.data().totalstars ? doc.data().totalstars: 0,
                             });
          });
      })
     
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      })

      .then(()=>{

        
        this.orderedidary=[];
        this.productdetailsary=[];
    
        this.afAuth.authState.subscribe(user=>{
          if(user){
          this.currentcustid=firebase.auth().currentUser.uid;
          this.afs.collection('users',ref=>ref.where('uid','==',this.currentcustid)).get().toPromise()
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                this.orderedidary=(doc.data().ordered) ? doc.data().ordered : [];
                if(this.orderedidary.length>0){
    for(let i=0;i<this.orderedidary.length;i++){
    
                      this.afs.collection("Orders").doc(this.orderedidary[i]).collection("productdetails").get().toPromise().then(querySnapshot => {
         if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
    this.productdetailsary.push((doc.data().pid))
            })
            console.log(this.productdetailsary)
            if(this.productdetailsary.includes(this.clickedprdid)){
              this.purchased=true;
              console.log("user has purchased this product")
                    this.isalreadyrated();
            }
            else{
              this.purchased=false;
              console.log("user hasn't purchased this product")
            }
         }
        })    
      }
                }
                else{
                  this.purchased=false;
                  console.log("user hasn't purchased this product and not logged")
                }
              })
            }
          })
        }
        })

      //  this.isalreadyrated();

      })
  }


  isalreadyrated(){
  
    this.afs.collection('Products',ref => ref.where('KEYID', '==', this.clickedprdid))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
       
       this.cptratedstars=doc.data().totalstars ? doc.data().totalstars :0;
       this.cptratedby=doc.data().numberofratings ? doc.data().numberofratings :0;
 
       if(doc.data().ratedby === undefined){ 
       this.isalreadyratedstatus=false;
     }
       if(doc.data().ratedby.length){
           if(doc.data().ratedby.includes(this.currentcustid)){
             this.isalreadyratedstatus=true;
             this.currentusercurrentprdrating();
 
           }
           else{
             this.isalreadyratedstatus=false;
           }
       }
       else{
         this.isalreadyratedstatus=false;
       }
          });
  })
 
   }

   
  currentusercurrentprdrating(){
  
    this.afs.collection('Ratingsreviews',ref => ref.where('uid', '==', this.currentcustid).where('pid', '==', this.clickedprdid))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
 this.cucprating=doc.data().ratings ? doc.data().ratings : 0;
 this.cucpratingmid=doc.data().mid;
 this.rating=doc.data().ratings ? doc.data().ratings : 0;
 this.review=doc.data().reviews ? doc.data().reviews : '';
 this.reviewtitle=doc.data().reviewstitle ? doc.data().reviewstitle : '';
 console.log( this.cucprating)
          });
  }).then(()=>{
    this.StarsComponent.setRating(this.cucprating)
  })

   }

onRatingSet(rating: number): void {
  //this.rating=0;
  console.log(`User set rating to ${rating}`);
 this.rating=rating;
}

submitreview(){

  if(this.rating!=0 && this.review!=''){
  this.currentcustid=firebase.auth().currentUser.uid;


 //this.isalreadyrated();

if(this.isalreadyratedstatus){
 console.log("yes, already rated")
 
 this.afs.collection('Ratingsreviews',ref => ref.where('uid', '==', this.currentcustid).where('pid', '==', this.clickedprdid))
 .get().toPromise()
 .then(querySnapshot => {
     querySnapshot.forEach(doc => {
this.cucprating=doc.data().ratings ? doc.data().ratings : 0;
this.cucpratingmid=doc.data().mid;
// this.rating=doc.data().ratings ? doc.data().ratings : 0;
// this.review=doc.data().reviews ? doc.data().reviews : '';
// this.reviewtitle=doc.data().reviewstitle ? doc.data().reviewstitle : '';
console.log( this.cucprating)

         });
 }).then(()=>{
   console.log("zz:"+(((this.cptratedstars)*(this.cptratedby))))
   console.log("zz:"+(((this.cptratedstars)*(this.cptratedby))+(this.rating)))
   console.log("zz:"+(((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating)))
   console.log("zz:"+(((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating))/(this.cptratedby))

 this.afs.collection('Products').doc(this.clickedprdid.toString()).set({
  // numberofratings:firebase.firestore.FieldValue.increment(1),
  totalstars:((((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating))/(this.cptratedby)).toFixed(2),
  ratedby:firebase.firestore.FieldValue.arrayUnion(this.currentcustid),
  customerratingtype:(((((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating))/(this.cptratedby))>=4) ? '4★ & above' :
  ((((((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating))/(this.cptratedby))>=3) ? '3★ & above' : 
  ((((((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating))/(this.cptratedby))>=2) ? '2★ & above' : 
  ((((((this.cptratedstars)*(this.cptratedby))+(this.rating)-(this.cucprating))/(this.cptratedby))>=1) ? '1★ & above' : (''))))
 },{merge:true})
 .then(()=>{
   this.afs.collection('users').doc(this.currentcustid).set({
     ratedto:firebase.firestore.FieldValue.arrayUnion(this.clickedprdid)
   },{merge:true})
 })
 .then(()=>{
   this.afs.collection('Ratingsreviews').doc(this.cucpratingmid).set({
       'ratings':this.rating,
       'reviews':this.review,
       'reviewstitle':this.reviewtitle,
       'uid':this.currentcustid.toString(),
       'pid':this.clickedprdid.toString(),
       'mid':this.cucpratingmid,
       'displayName':(firebase.auth().currentUser.displayName !=null) ? firebase.auth().currentUser.displayName : 'MY ACCOUNT',
       'publishedon': firebase.firestore.FieldValue.serverTimestamp(),
   },{merge:true})
 })
 .then(()=>{
  this.router.navigate(
    ['productdescription'], 
    {
      queryParams: {myprParam: JSON.stringify(this.clickedprdid.toString()) } ,
      // queryParamsHandling: 'merge'
    });
 })
})
}
else{
 console.log("no, not yet rated")
 this.afs.collection('Products').doc(this.clickedprdid.toString()).set({
   numberofratings:firebase.firestore.FieldValue.increment(1),
   totalstars:((((this.cptratedstars)*(this.cptratedby))+(this.rating))/((this.cptratedby)+1)).toFixed(2),
   ratedby:firebase.firestore.FieldValue.arrayUnion(this.currentcustid),
   customerratingtype:(((((this.cptratedstars)*(this.cptratedby))+(this.rating))/((this.cptratedby)+1))>=4) ? '4★ & above' :
   ((((((this.cptratedstars)*(this.cptratedby))+(this.rating))/((this.cptratedby)+1))>=3) ? '3★ & above' : 
   ((((((this.cptratedstars)*(this.cptratedby))+(this.rating))/((this.cptratedby)+1))>=2) ? '2★ & above' : 
   ((((((this.cptratedstars)*(this.cptratedby))+(this.rating))/((this.cptratedby)+1))>=1) ? '1★ & above' : (''))))
 },{merge:true})
 .then(()=>{
   this.afs.collection('users').doc(this.currentcustid).set({
     ratedto:firebase.firestore.FieldValue.arrayUnion(this.clickedprdid)
   },{merge:true})
 })
 .then(()=>{
   let ids =this.afs.createId();
   this.afs.collection('Ratingsreviews').doc(ids).set({
       'ratings':this.rating,
       'reviews':this.review,
       'reviewstitle':this.reviewtitle,
       'uid':this.currentcustid.toString(),
       'pid':this.clickedprdid.toString(),
       'mid':ids,
       'displayName':(firebase.auth().currentUser.displayName !=null) ? firebase.auth().currentUser.displayName : 'MY ACCOUNT',
       'publishedon': firebase.firestore.FieldValue.serverTimestamp(),
   },{merge:true})
 })
 .then(()=>{
  this.router.navigate(
    ['productdescription'], 
    {
      queryParams: {myprParam: JSON.stringify(this.clickedprdid.toString()) } ,
      // queryParamsHandling: 'merge'
    });
 })
}
  }
  else{
    if(this.rating!=0){
console.log("star missing");
    }
    if(this.review!=''){
      console.log("review missing");

    }
  }
}
productclicked(prdid){
  this.router.navigate(
    ['productdescription'], 
    {
      queryParams: {myprParam: JSON.stringify(prdid) } ,
      // queryParamsHandling: 'merge'
    });
}
}
