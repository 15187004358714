import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductdescriptionService } from "../../assets/services/productdescription.service";
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';
import {MenuContextualService} from './menu-contextual.service'
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-productfilter',
  templateUrl: './productfilter.component.html',
  styleUrls: ['./productfilter.component.css']
})
export class ProductfilterComponent implements OnInit {


  isExpanded: boolean=false;

  brcollection: any;


  mybrndary: { brndid: any, brandname: any, checked: boolean }[]= [] //declare before constructor
  myctryary: { ctryid: any, categoryname: any, checked: boolean }[]= [] //declare before constructor
  mycustratingary: { custratingid: any, custratingname: any, checked: boolean }[]= [] //declare before constructor
  mydiscountary: { discountid: any, discountname: any, checked: boolean }[]= [] //declare before constructor

  selectedBrand:any[]=[];
  selectedCategory:any[]=[];
  selectedcustrating:any[]=[];
  selecteddiscount:any[]=[];

  products: any[]=[];
  productsFilter:any=[];
  currentcustid: string;


  constructor(public afAuth: AngularFireAuth, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private popupService:MenuContextualService, private viewContainerRef:ViewContainerRef, private afs: AngularFirestore, private data: ProductdescriptionService, private route: ActivatedRoute, public router: Router) {

this.afAuth.authState.subscribe(user=>{
  if(user){
  this.currentcustid=firebase.auth().currentUser.uid;
  }
})


  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }

  open(origin:any,menu:any,index:number)
  {
    this.popupService.open(origin,menu,this.viewContainerRef,{data:'I\'m the button '+index})
    .subscribe(res=>{
      console.log(res)
    })
  }

  brndsearch = new FormControl();
  custratingControl = new FormControl();
  discountControl = new FormControl();

  $brsearch = this.brndsearch.valueChanges.pipe(
    startWith(null),
    debounceTime(200),
    switchMap((res: string) => {
      if (!res) return of(this.mybrndary);
      res = res.toUpperCase();
      return of(
        this.mybrndary.filter(x => x.brandname.toUpperCase().indexOf(res) >= 0)
      );
    })
    //, map(res => this.searchlength=res.length)
  );

  
  ngOnInit() {

    
    this.route.queryParamMap.subscribe((params: any) => {
      console.log("ZZZZZ"+params.params.mybrParam)
      console.log("AAAAA1"+params.params.mycrParam)

      // this.selectedBrand = JSON.parse(params.params.mybrParam);
      this.selectedBrand = (params.params.mybrParam!=undefined && params.params.mybrParam.length> 0) ? JSON.parse(params.params.mybrParam) : [];
      //this.selectedCategory =["VOLLEYBALL MOULDED ACCLAIM"];
      this.selectedCategory =["PROCIN"];

      this.selectedcustrating = (params.params.mycrParam!=undefined && params.params.mycrParam.length> 0) ? JSON.parse(params.params.mycrParam) : [];
      console.log("AAAAA2"+params.params.mycrParam)

      //this.selecteddiscount = JSON.parse(params.params.mydtParam);
      this.selecteddiscount = (params.params.mydtParam!=undefined && params.params.mydtParam.length> 0) ? JSON.parse(params.params.mydtParam) : [];
      });  

    this.mybrndary=[];

    this.mycustratingary=[
      {'custratingid':0,'custratingname':'4★ & above', 'checked': this.selectedcustrating.some(x => x =="4★ & above") ? true : false},
      {'custratingid':1,'custratingname':'3★ & above', 'checked': this.selectedcustrating.some(x => x =="3★ & above") ? true : false},
      {'custratingid':2,'custratingname':'2★ & above', 'checked': this.selectedcustrating.some(x => x =="2★ & above") ? true : false},
      {'custratingid':3,'custratingname':'1★ & above', 'checked': this.selectedcustrating.some(x => x =="1★ & above") ? true : false},
    ];

    
    this.mydiscountary=[
      {'discountid':0,'discountname':'70% or more', 'checked': this.selecteddiscount.some(x => x =="70% or more") ? true : false},
      {'discountid':1,'discountname':'60% or more', 'checked': this.selecteddiscount.some(x => x =="60% or more") ? true : false},
      {'discountid':2,'discountname':'50% or more', 'checked': this.selecteddiscount.some(x => x =="50% or more") ? true : false},
      {'discountid':3,'discountname':'40% or more', 'checked': this.selecteddiscount.some(x => x =="40% or more") ? true : false},
      {'discountid':4,'discountname':'30% or more', 'checked': this.selecteddiscount.some(x => x =="30% or more") ? true : false},
      {'discountid':5,'discountname':'30% and below', 'checked': this.selecteddiscount.some(x => x =="30% and below") ? true : false},
    ];

  this.brcollection = this.afs.collection('Brands').snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.brcollection.subscribe((posts) => {
    this.afs.collection('Brands')
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        
            if(this.selectedBrand.some(x => x == doc.data().brandname)){
              this.mybrndary.push({'brndid':doc.id, 'brandname':doc.data().brandname, 'checked':true});
            }else{
              this.mybrndary.push({'brndid':doc.id, 'brandname':doc.data().brandname, 'checked':false});
            }

      });
  }).then(()=>{
  this.statuscheck();
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

})


this.afs.collection('Products')
.get().toPromise()
.then(querySnapshot => {
  querySnapshot.forEach(doc => {
    this.products.push(doc.data());

    
    if(this.selectedCategory.some(x => x == doc.data().category)){
      this.myctryary.push({'ctryid':doc.id, 'categoryname':doc.data().category, 'checked':true});
    }else{
      this.myctryary.push({'ctryid':doc.id, 'categoryname':doc.data().category, 'checked':false});
    }

  });
})
.catch(function(error) {
  console.log("Error getting documents: ", error);
})
.then(()=>{

})


  }


  updatebrndCheckedOptions(z) {
    console.log(z)
    z.checked = !z.checked;
    if (z.checked == true) {
      this.selectedBrand.indexOf(z.brandname)===-1 ? this.selectedBrand.push(z.brandname) : console.log("item already exists in array");
      console.log("true", JSON.stringify(this.selectedBrand));
    }
    if (z.checked == false) {
      this.selectedBrand = this.selectedBrand.filter(obj => obj !== z.brandname);
      console.log("false", JSON.stringify(this.selectedBrand));
    }

    this.router.navigate(
      [], 
      {
        queryParams: {mybrParam: this.selectedBrand.length>0 ? JSON.stringify(this.selectedBrand) : null } ,
        queryParamsHandling: 'merge'
      });


    this.statuscheck();
  }

  updatecustratingCheckedOptions(y){
    y.checked = !y.checked;
    if(y.checked==true){
     this.selectedcustrating.indexOf(y.custratingname)===-1 ? this.selectedcustrating.push(y.custratingname) : console.log("item already exists in array");
     console.log("true",JSON.stringify(this.selectedcustrating));
    }
    if(y.checked==false){
      this.selectedcustrating = this.selectedcustrating.filter(obj => obj !== y.custratingname);
      console.log("false",JSON.stringify(this.selectedcustrating));
    }

    this.router.navigate(
      [], 
      {
        queryParams: {mycrParam: this.selectedcustrating.length>0 ? JSON.stringify(this.selectedcustrating) : null } ,
        queryParamsHandling: 'merge'
      });

    this.statuscheck();
  }

  updatediscountCheckedOptions(x){
    x.checked = !x.checked;
    if(x.checked==true){
      this.selecteddiscount.indexOf(x.discountname)===-1 ? this.selecteddiscount.push(x.discountname) : console.log("item already exists in array");
      console.log("true",JSON.stringify(this.selecteddiscount));
    }
    if(x.checked==false){
      this.selecteddiscount = this.selecteddiscount.filter(obj => obj !== x.discountname);
      console.log("false",JSON.stringify(this.selecteddiscount));
    }

    this.router.navigate(
      [], 
      {
        queryParams: {mydtParam: this.selecteddiscount.length>0 ? JSON.stringify(this.selecteddiscount) : null } ,
        queryParamsHandling: 'merge'
      });

    this.statuscheck();
  }

  updateWishlist(o){
    this.afAuth.authState.subscribe(user=>{
      if(user){
        this.currentcustid=firebase.auth().currentUser.uid;

        o.checked = !o.checked;
        console.log(o.code,this.currentcustid)
        if(o.checked==true){
          this.afs.collection('Products').doc(o.KEYID).set({
            wishlist:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
          },{merge:true}).then(()=>{
            this.afs.collection('users').doc(this.currentcustid).set({
              wishlist:firebase.firestore.FieldValue.arrayUnion(o.KEYID)
            },{merge:true})
          })
        }
        if(o.checked==false){
          this.afs.collection('Products').doc(o.KEYID).set({
            wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
          },{merge:true}).then(()=>{
            this.afs.collection('users').doc(this.currentcustid).set({
              wishlist:firebase.firestore.FieldValue.arrayRemove(o.KEYID)
            },{merge:true})
          })
        }
      }
      else{
        this.router.navigate(
          ['sign-in'], 
          {
            queryParams: { redirectURL: this.router.url } 
          });
      }
      })

    // this.currentcustid=firebase.auth().currentUser.uid;

    // o.checked = !o.checked;
    // console.log(o.code,this.currentcustid)
    // if(o.checked==true){
    //   this.afs.collection('Products').doc(o.KEYID).set({
    //     wishlist:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
    //   },{merge:true}).then(()=>{
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       wishlist:firebase.firestore.FieldValue.arrayUnion(o.KEYID)
    //     },{merge:true})
    //   })
    // }
    // if(o.checked==false){
    //   this.afs.collection('Products').doc(o.KEYID).set({
    //     wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
    //   },{merge:true}).then(()=>{
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       wishlist:firebase.firestore.FieldValue.arrayRemove(o.KEYID)
    //     },{merge:true})
    //   })
    // }
  }
  iswishlisted(o){
    if(o.wishlist === undefined) {return false}
    if(o.wishlist.length){
        return (o.wishlist.includes(this.currentcustid))
    }
    }

    
    
  statuscheck(){
    // console.log()
    // if(this.selectedBrand.length==0 && this.selectedCategory.length==0 && this.selectedcustrating.length==0 && this.selecteddiscount.length==0){
    //   this.productsFilter == this.products
    // }
    // else{
    // this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, customerratingtype: this.selectedcustrating, discounttype: this.selecteddiscount};
    // console.log(this.productsFilter)
    // }

    // var index= this.selectedcustrating.indexOf('1★ & above')
    // if(index>-1){
    // this.selectedcustrating.splice(index,1)
    //this.selectedcustrating.push('1★ & above','2★ & above','3★ & above','4★ & above')
    //}
  
//     if(this.selectedcustrating.indexOf('4★ & above') !== -1){
//   this.selectedcustrating=[]
//   this.selectedcustrating.push('4★ & above')
// }else if(this.selectedcustrating.indexOf('3★ & above') !== -1){
//   this.selectedcustrating=[]
//   this.selectedcustrating.push('3★ & above','4★ & above')
// }else if(this.selectedcustrating.indexOf('2★ & above') !== -1){
//   this.selectedcustrating=[]
//   this.selectedcustrating.push('2★ & above','3★ & above','4★ & above')
// }else if(this.selectedcustrating.indexOf('1★ & above') !== -1){
//   this.selectedcustrating=[]
//   this.selectedcustrating.push('1★ & above','2★ & above','3★ & above','4★ & above')
// }else{
//   this.selectedcustrating=[]
// }

    console.log(this.selectedcustrating)

    if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = '';
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {customerratingtype: this.selectedcustrating};
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {customerratingtype: this.selectedcustrating, discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {category: this.selectedCategory};
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {category: this.selectedCategory, discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {category: this.selectedCategory, customerratingtype: this.selectedcustrating, };
    }
    else if (this.selectedBrand.length == 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {category: this.selectedCategory, customerratingtype: this.selectedcustrating, discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {brand: this.selectedBrand};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {brand: this.selectedBrand, discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {brand: this.selectedBrand, customerratingtype: this.selectedcustrating};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length == 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {brand: this.selectedBrand, customerratingtype: this.selectedcustrating, discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length == 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, discounttype: this.selecteddiscount};
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length == 0) {
      this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, customerratingtype: this.selectedcustrating, };
    }
    else if (this.selectedBrand.length != 0 && this.selectedCategory.length != 0 && this.selectedcustrating.length != 0 && this.selecteddiscount.length != 0) {
      this.productsFilter = {brand: this.selectedBrand, category: this.selectedCategory, customerratingtype: this.selectedcustrating, discounttype: this.selecteddiscount};
    }
  }



  sortbyrate: any;
  sortbycategory: any;
  sortbytag: any;
  sortbynew: any;
  sortbybrand: any;

  hightolowrate(){
    console.log("called htl")
    this.products = this.products.sort((a, b) => a.onlinerate > b.onlinerate ? -1 : a.onlinerate < b.onlinerate ? 1 : 0) //descending rate
    this.statuscheck();
  }
  
  lowtohighrate(){  
    console.log("called lth")
      this.products = this.products.sort((a, b) => a.onlinerate < b.onlinerate ? -1 : a.onlinerate > b.onlinerate ? 1 : 0) //ascending rate
      this.statuscheck();
  }

  atozcategory(){
    this.products = this.products.sort((a, b) => a.category < b.category ? -1 : a.category > b.category ? 1 : 0) //ascending category
    this.statuscheck();
  }

  ztoacategory(){
    this.products = this.products.sort((a, b) => a.category > b.category ? -1 : a.category < b.category ? 1 : 0) //descending category
    this.statuscheck();
  }
  
  bypriority(){
    var sortOrder = ['Trending', 'Popular', 'New'];
      this.products = this.products.sort((a, b) => sortOrder.indexOf(a.tag) - sortOrder.indexOf(b.tag));
      this.statuscheck();
  }

  popularfirst(){
    var sortOrder = ['Popular'];
      this.products = this.products.sort((a, b) => sortOrder.indexOf(b.tag) - sortOrder.indexOf(a.tag));
      this.statuscheck();
  }

  newestfirst(){
    var sortOrder = ['New'];
      this.products = this.products.sort((a, b) => sortOrder.indexOf(b.tag) - sortOrder.indexOf(a.tag));
      this.statuscheck();
  }

  atozbrand(){
    this.products = this.products.sort((a, b) => a.brand < b.brand ? -1 : a.brand > b.brand ? 1 : 0) //ascending brand
    this.statuscheck();
  }
  
  ztoabrand(){
    this.products = this.products.sort((a, b) => a.brand > b.brand ? -1 : a.brand < b.brand ? 1 : 0) //descending brand
    this.statuscheck();
  }
  
  resettogglebtns(){
    this.sortbyrate=null;
    this.sortbycategory=null;
    this.sortbytag=null;
    this.sortbynew=null;
    this.sortbybrand=null;
  }

  


  showmorelessItems() {
    this.isExpanded = !this.isExpanded;
  }
  navigate(prdid){

    // this.router.navigate(
    //   ['productdescription'], 
    //   {
    //     queryParams: {myprParam: JSON.stringify(prdid) } ,
    //     // queryParamsHandling: 'merge'
    //   });

    window.open(window.location.origin+'/productdescription?myprParam="'+prdid+'"', '_blank')

  }
  
}

