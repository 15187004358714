import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {ChangePassworddialog} from './change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangeEmaildialog } from './change-email.component';
import { ChangeMobiledialog } from './change-mobile.component';

declare var $: any;

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})

export class MyprofileComponent implements OnInit {

  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  //------------------PI START------------------
  public piForm: FormGroup;

  fsname: string;
  lsname: string;
  selectedgender: string;

  isnameedit: boolean=false;

  emailid: string;
  isemailedit: boolean=false;

  pimobileno: string;
  ismobileedit: boolean=false;
  //------------------PI END------------------

  //-----------------MAFORM START------------------
  public maForm: FormGroup;

  fname: string;
  mnumber: string;

  pcode: string;
  local: string;

  adress: string;

  dist: string;
  stte: string;

  lmark: string;
  altphone: string;

  addrtype:string;
  currentcustid: string;
  currentcustname: string='';
  loadaddresses: any;

KEYID: string;

savebtnvisible: boolean;
maFormvisible: boolean;
//-----------------MAFORM END------------------

//------------------R&R START------------------
orderedidary: any;
productdetailsary: any;

ratingsreviewsary: any;
ratingsreviewsarylength: any;

cucprating: number=0;
cptratedstars: any;
cptratedby: any;
//-------------------R&R END-------------------

  constructor(private dialog: MatDialog, private ren: Renderer2, private fb: FormBuilder, public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private afs: AngularFirestore, private http:HttpClient, public router: Router) {
    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      // this.currentcustname=firebase.auth().currentUser.displayName;
      this.currentcustname=(firebase.auth().currentUser.displayName !=null) ? firebase.auth().currentUser.displayName : 'MY ACCOUNT';
    }
    })

  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }

  loadwishlistproducts: any[]= []; //declare before constructor
  loadwishlist: any;
  addorreplace(array, item){
    const i=array.findIndex(_item => _item.KEYID===item.KEYID);
    if(i>-1) array[i] =item;
    else array.push(item);
   }
   addorreplace2(array, item){
    const i=array.findIndex(_item => _item.pid===item.pid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }
  
  ngOnInit() {

    //-----------------ACCOUNT SETTING START------------------
    this.Form();  
    this.ProfileInformationFunction();

    this.fsname='';
    this.lsname='';
    this.selectedgender='';
    
    this.emailid='';

    this.pimobileno='';

    this.fname='';
    this.mnumber='';

    this.pcode='';
    this.local='';

    this.adress='';

    this.dist='';
    this.stte='';

    this.lmark='';
    this.altphone='';

    this.addrtype="home";

    this.KEYID='';


    this.savebtnvisible=true;
    this.maFormvisible=false;

    //-----------------ACCOUNT SETTING END------------------


    //------------------PI START------------------
    this.piForm.controls['firstname'].disable()
    this.piForm.controls['lastname'].disable()

    this.piForm.controls['gender'].disable()

    this.piForm.controls['emailaddress'].disable()

    this.piForm.controls['pimobilenumber'].disable()

    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;    
      this.emailid=firebase.auth().currentUser.email;  
      this.pimobileno=firebase.auth().currentUser.phoneNumber;

      this.afs.collection("users",ref=>ref.where('uid','==',this.currentcustid)).get().toPromise().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            this.fsname= (doc.data().displayName != "MY ACCOUNT") ? (doc.data().displayName) : '';
            this.lsname= (doc.data().lastName != null) ? (doc.data().lastName) : '';
            this.selectedgender= (doc.data().gender != null) ? (doc.data().gender) : '';
          })
        }
      })
      }
      else{
        this.router.navigate(
          ['sign-in'], 
          {
            queryParams: { redirectURL: this.router.url } 
          });
      }
    })

    //------------------PI END------------------
    
//------------------R&R START------------------

this.afAuth.authState.subscribe(user=>{
  if(user){
  this.currentcustid=firebase.auth().currentUser.uid;

  this.ratingsreviewsary =[];
  this.ratingsreviewsarylength=0;
  
    console.log(this.currentcustid)
  this.ratingsreviewsary=this.afs.collection("Ratingsreviews",ref=> ref.where('uid', '==', this.currentcustid).limit(5)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );


this.orderedidary=[];
this.productdetailsary=[];

  this.afs.collection('users',ref=>ref.where('uid','==',this.currentcustid)).get().toPromise()
  .then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(docz => {
        this.orderedidary=(docz.data().ordered) ? docz.data().ordered : [];
        if(this.orderedidary.length>0){
for(let i=0;i<this.orderedidary.length;i++){
 this.afs.collection("Orders").doc(this.orderedidary[i]).collection("productdetails").get().toPromise().then(querySnapshot => {
 if (!querySnapshot.empty) {
    querySnapshot.forEach(doc => {
this.addorreplace2(this.productdetailsary,doc.data());
    })
    console.log(this.productdetailsary)
 }
})

}
        }
      })
    }
  })
}
})

//-------------------R&R END-------------------


    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;

      this.loadaddresses =[];
    this.loadaddresses=this.afs.collection("users").doc(this.currentcustid).collection("addresses",ref=>ref.orderBy('timestamp','asc')).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => a.payload.doc.data()))
      );
    

this.loadwishlist =[];
this.afs.collection("users", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
  if (!querySnapshot.empty) {
    querySnapshot.forEach(doc => {
      this.loadwishlist=doc.data().wishlist
      console.log(this.loadwishlist)
    })
  }
}).then(()=>{
  this.loadwishlistproducts =[];
  for(let i=0;i < this.loadwishlist.length;i++){
  this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadwishlist[i] )).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.addorreplace(this.loadwishlistproducts,doc.data());
      })
    }
})
  }
})
    }
})
  }

    pidiv:boolean=true;
    madiv:boolean=false;
    //div3:boolean=false;

    rrdiv:boolean=true;
    andiv:boolean=false;
    wldiv:boolean=false;

    ProfileInformationFunction(){
      
    var el =document.getElementsByClassName('piitem') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="color:royalblue !IMPORTANT;background-color: ghostwhite !IMPORTANT;"

    var el =document.getElementsByClassName('maitem') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
    // var el =document.getElementsByClassName('item3') as HTMLCollectionOf<HTMLElement>;
    // el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

    var el =document.getElementsByClassName('rritem') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
    var el =document.getElementsByClassName('anitem') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
    var el =document.getElementsByClassName('wlitem') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

        this.pidiv=true;
        this.madiv=false;
        //this.div3=false;

        this.rrdiv=false;
        this.andiv=false;
        this.wldiv=false;
    }

    ManageAddressesFunction(){
      
      var el =document.getElementsByClassName('maitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:royalblue !IMPORTANT;background-color: ghostwhite !IMPORTANT;"

      var el =document.getElementsByClassName('piitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      // var el =document.getElementsByClassName('item3') as HTMLCollectionOf<HTMLElement>;
      // el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

      var el =document.getElementsByClassName('rritem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('anitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('wlitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

        this.madiv=true;
        this.pidiv=false;
        //this.div3=false;

        this.rrdiv=false;
        this.andiv=false;
        this.wldiv=false;
    }

    // div3Function(){
      
    //   var el =document.getElementsByClassName('item3') as HTMLCollectionOf<HTMLElement>;
    //   el[0].style.cssText="color:royalblue !IMPORTANT;background-color: ghostwhite !IMPORTANT;"
      
    //   var el =document.getElementsByClassName('piitem') as HTMLCollectionOf<HTMLElement>;
    //   el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
    //   var el =document.getElementsByClassName('maitem') as HTMLCollectionOf<HTMLElement>;
    //   el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

    //   var el =document.getElementsByClassName('rritem') as HTMLCollectionOf<HTMLElement>;
    //   el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
    //   var el =document.getElementsByClassName('anitem') as HTMLCollectionOf<HTMLElement>;
    //   el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
    //   var el =document.getElementsByClassName('wlitem') as HTMLCollectionOf<HTMLElement>;
    //   el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

    //     this.div3=true;
    //     this.madiv=false;
    //     this.pidiv=false;

    //     this.rrdiv=false;
    //     this.andiv=false;
    //     this.wldiv=false;
    // }

    RatingReviewFunction(){
      var el =document.getElementsByClassName('maitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('piitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      // var el =document.getElementsByClassName('item3') as HTMLCollectionOf<HTMLElement>;
      // el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

      var el =document.getElementsByClassName('rritem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:royalblue !IMPORTANT;background-color: ghostwhite !IMPORTANT;"

      var el =document.getElementsByClassName('anitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('wlitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

        this.madiv=false;
        this.pidiv=false;
        //this.div3=false;

        this.rrdiv=true;
        this.andiv=false;
        this.wldiv=false;
    }

    AllNotificationFunction(){
      var el =document.getElementsByClassName('maitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('piitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      // var el =document.getElementsByClassName('item3') as HTMLCollectionOf<HTMLElement>;
      // el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

      var el =document.getElementsByClassName('anitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:royalblue !IMPORTANT;background-color: ghostwhite !IMPORTANT;"

      var el =document.getElementsByClassName('rritem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('wlitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

        this.madiv=false;
        this.pidiv=false;
        //this.div3=false;

        this.andiv=true;
        this.rrdiv=false;
        this.wldiv=false;
    }

    WishlistFunction(){
      var el =document.getElementsByClassName('maitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('piitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      // var el =document.getElementsByClassName('item3') as HTMLCollectionOf<HTMLElement>;
      // el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

      var el =document.getElementsByClassName('wlitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:royalblue !IMPORTANT;background-color: ghostwhite !IMPORTANT;"

      var el =document.getElementsByClassName('rritem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"
      var el =document.getElementsByClassName('anitem') as HTMLCollectionOf<HTMLElement>;
      el[0].style.cssText="color:black !IMPORTANT;background-color: white !IMPORTANT;"

        this.madiv=false;
        this.pidiv=false;
        //this.div3=false;

        this.wldiv=true;
        this.rrdiv=false;
        this.andiv=false;
    }


 //-----------------MAFORM START------------------
 // Reactive form
 Form() {
  this.maForm = this.fb.group({
    fullname: ['',[Validators.required, Validators.minLength(3)]],
    mobilenumber: ['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],

    pincode:  ['',[Validators.required, Validators.minLength(6)]],
    locality: [''],

    address:  ['',[Validators.required, Validators.minLength(10)]],

    district: ['',[Validators.required, Validators.minLength(1)]],
    state: ['',[Validators.required, Validators.minLength(1)]],

    landmark: [''],
    alternatephone: [''],

    addresstype: [''],
  })


  this.piForm = this.fb.group({
    firstname: ['',[Validators.required, Validators.minLength(3)]],
    lastname: ['',[Validators.required, Validators.minLength(3)]],
    gender: ['',[Validators.required]],
    emailaddress: ['',[Validators.required, Validators.email]],
    pimobilenumber: ['',[Validators.required, Validators.minLength(3)]],
  })
}

    get fullname() {
      return this.maForm.get('fullname');
    }
    get mobilenumber() {
      return this.maForm.get('mobilenumber');
    }

    get pincode() {
      return this.maForm.get('pincode');
    }
    get locality() {
      return this.maForm.get('locality');
    }

    get address() {
      return this.maForm.get('address');
    }

    get district() {
      return this.maForm.get('district');
    }
    get state() {
      return this.maForm.get('state');
    }

    get landmark() {
      return this.maForm.get('landmark');
    }
    get alternatephone() {
      return this.maForm.get('alternatephone');
    }

    get addresstype() {
      return this.maForm.get('addresstype');
    }


    get firstname() {
      return this.piForm.get('firstname');
    }

    get lastname() {
      return this.piForm.get('lastname');
    }

    get gender() {
      return this.piForm.get('gender');
    }

    get emailaddress() {
      return this.piForm.get('emailaddress');
    }

    get pimobilenumber() {
      return this.piForm.get('pimobilenumber');
    }

    submitmaData() {

      if(this.maForm.valid){
      this.afs.collection('users').doc(this.currentcustid).collection("addresses").get().toPromise().then(querySnapshot => {
          if(querySnapshot.size < 4){
        this.afs.collection('users').doc(this.currentcustid).collection("addresses", ref => ref.where("address", "==", this.maForm.value.address).where("fullname", "==", this.maForm.value.fullname)).get().toPromise().then(querySnapshot => {
        if (querySnapshot.empty) {
            this.maadd();
        }
        else{
          querySnapshot.forEach(doc => {
            this.KEYID=doc.data().KEYID;
          this.updateaddress()
          })
        }
      })
          }
          else{
            this.openSnackBar("You can't save more than 4 addresses", 'OK')
          }
    });
      }
      else{
        console.log("form inVlid")
      }
      //this.ResetForm();  // Reset form when clicked on reset button
      //this.resetproductdetails();
    };

    maadd() {

      let ids =this.afs.createId();
      this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(ids).set({
        KEYID:ids,
        fullname: this.fname.toUpperCase(),
        mobile: this.mnumber,
        pincode: this.pcode,
        locality: this.local,
        address: this.adress,
        district: this.dist,
        state: this.stte,
        landmark: this.lmark,
        alternatephone: this.altphone,
        addresstype: this.addrtype,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        primaryaddress: false
      }).then(()=>{

        this.afs.collection('users').doc(this.currentcustid).collection("addresses").get().toPromise().then(querySnapshot => {
          if(querySnapshot.size == 1){
            querySnapshot.forEach(doc => {
              this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(String(doc.data().KEYID)).update({
                primaryaddress: true
            });
            })
          }
            this.openSnackBar('New Address Added Successfully', 'OK')
            this.clear();
      })
        })
            .catch((err) => {
          console.log(err);
        })

    }


    refreshpincode(pcode){
      if(pcode.length==6){
      this.http.get('https://api.postalpincode.in/pincode/'+pcode).subscribe((res)=>{
      //console.log(res);
      if(res[0].Status=="Success"){
        this.dist=res[0].PostOffice[0].District;
        this.stte=res[0].PostOffice[0].State;
      }
      else{
        console.log("Invalid Pincode");
        this.dist='';
        this.stte='';
      }
    })
  }
  else{
    //console.log("Incomplete Pincode");
        this.dist='';
        this.stte='';
  }
  }

addanewaddressclick(){
  if(this.currentcustname==''){
    this.openSnackBar('Please Login first to proceed', 'OK')
  }
  if(this.currentcustname!=''){
this.maFormvisible=true;
  }
}
editaddress(a){
    this.maFormvisible=true;

  this.savebtnvisible=false;

      this.fname = a.fullname;
      this.mnumber=a.mobile;
      this.pcode= a.pincode,
      this.local= a.locality;
      this.adress = a.address;
      this.dist = a.district;
      this.stte = a.state;
      this.lmark = a.landmark;
      this.altphone = a.alternatephone;
      this.addrtype = a.addresstype;

      this.KEYID=a.KEYID;
      }
updateaddress(){
      this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(this.KEYID).update({
        fullname: this.fname.toUpperCase(),
        mobile: this.mnumber,
        pincode: this.pcode,
        locality: this.local,
        address: this.adress,
        district: this.dist,
        state: this.stte,
        landmark: this.lmark,
        alternatephone: this.altphone,
        addresstype: this.addrtype,
        timestamp:firebase.firestore.FieldValue.serverTimestamp()
      }).then(()=>{
                this.openSnackBar('Address Updated Successfully', 'OK')
                this.clear();
      })
}
deleteaddress(id){
  console.log(id)
      this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(id).delete().then(() => {
      console.log('deleted');
    })
}

clear(){
  this.maFormvisible=false;

  this.savebtnvisible=true;

      // this.fname = '';
      // this.mnumber= '';
      // this.pcode= '';
      // this.local= '';
      // this.adress = '';
      // this.dist = '';
      // this.stte = '';
      // this.lmark = '';
      // this.altphone = '';
      // this.addrtype = 'home';

         this.maForm.reset();
         this.addrtype = 'home';
         this.KEYID= '';
}
//-----------------MAFORM END------------------

//-------------------PI START-------------------
nameedit(){
  this.isnameedit=!this.isnameedit;
  if(this.isnameedit==true){
    this.piForm.controls['firstname'].enable()
    this.piForm.controls['lastname'].enable()
    this.piForm.controls['gender'].enable()
  }
  if(this.isnameedit==false){
    this.piForm.controls['firstname'].disable()
    this.piForm.controls['lastname'].disable()
    this.piForm.controls['gender'].disable()
  }
}
savepiname(){
  this.openSnackBar("In demo mode, you cant use this service","OK");
}
emailedit(){
  this.isemailedit=!this.isemailedit;
  if(this.isemailedit==true){
    this.piForm.controls['emailaddress'].enable()
  }
  if(this.isemailedit==false){
    this.piForm.controls['emailaddress'].disable()
  }
}
openDialogce() {
  const dialogRef = this.dialog.open(ChangeEmaildialog,{
    data:{
      message: '',
      buttonText: {
        ok: 'CHANGE EMAIL ADDRESS',
        cancel: 'CANCEL'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {

        }
  });
}
savepiemail(){
  this.openDialogce();
  this.openSnackBar("OTP sent to "+this.pimobileno+" successfully", 'OK')
}
openDialogcp() {
  const dialogRef = this.dialog.open(ChangePassworddialog,{
    data:{
      message: '',
      buttonText: {
        ok: 'CHANGE PASSWORD',
        cancel: 'CANCEL'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {

        }
  });
}
changepasswordclk(){
  this.openDialogcp();
  this.openSnackBar("OTP sent to "+this.pimobileno+" successfully", 'OK')
}
mobileedit(){
  this.ismobileedit=!this.ismobileedit;
  if(this.ismobileedit==true){
    this.piForm.controls['pimobilenumber'].enable()
  }
  if(this.ismobileedit==false){
    this.piForm.controls['pimobilenumber'].disable()
  }
}
openDialogcm() {
  const dialogRef = this.dialog.open(ChangeMobiledialog,{
    data:{
      message: '',
      buttonText: {
        ok: 'CHANGE MOBILE NUMBER',
        cancel: 'CANCEL'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {

        }
  });
}
savepimobile(){
  this.openDialogcm();
  this.openSnackBar("OTP sent to "+this.pimobileno+" successfully", 'OK')
}
//-------------------PI END-------------------

//-------------------R&R START-------------------
ratereviews(prdid){
  this.router.navigate(
    ['ratingsandreviews'], 
    {
      queryParams: {myprParam: JSON.stringify(prdid) } ,
      // queryParamsHandling: 'merge'
    });
}

ratereviewsdelete(prdid){
  this.productdetailsary=this.productdetailsary.filter(item => item !== prdid)
  this.currentcustid=firebase.auth().currentUser.uid;
  // this.afs.collection('Products').doc(prdid).set({
  //   numberofratings:firebase.firestore.FieldValue.increment(-1),
  //   ratedby:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
  // },{merge:true}).then(()=>{
  //   this.afs.collection('users').doc(this.currentcustid).set({
  //     ratedto:firebase.firestore.FieldValue.arrayRemove(prdid)
  //   },{merge:true})
  // }).then(()=>{
  //   this.afs.collection('Ratingsreviews',ref=>ref.where('uid','==',this.currentcustid).where('pid','==',prdid)).get().toPromise().then(function(querySnapshot) {
  //     querySnapshot.forEach(function(doc) {
  //       doc.ref.delete();
  //     });
  //   });
  // })
  
// this.afs.collection('Products',ref => ref.where('KEYID', '==', prdid))
// .get().toPromise()
// .then(querySnapshot => {
//     querySnapshot.forEach(doc => {
//       this.cptratedstars=doc.data().totalstars ? doc.data().totalstars : 0;
//       this.cptratedby=doc.data().numberofratings ? doc.data().numberofratings : 0;

//     });

//     })

  this.afs.collection('Ratingsreviews',ref=>ref.where('uid','==',this.currentcustid).where('pid','==',prdid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docr => {
      console.log(docr.data().ratings)
     this.cucprating=docr.data().ratings ? docr.data().ratings : 0;
    });
  })
  this.afs.collection('Products',ref=>ref.where('KEYID','==',prdid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docp => {
      this.cptratedstars=docp.data().totalstars ? docp.data().totalstars :0;
      this.cptratedby=docp.data().numberofratings ? docp.data().numberofratings :0;
    });
  }).then(()=>{
    console.log(this.cucprating,this.cptratedstars,this.cptratedby)
this.afs.collection('Products').doc(prdid).set({
  totalstars:((((this.cptratedstars)*(this.cptratedby))-(this.cucprating))/(this.cptratedby - 1)).toFixed(2),
  numberofratings:firebase.firestore.FieldValue.increment(-1),
  ratedby:firebase.firestore.FieldValue.arrayRemove(this.currentcustid),
  customerratingtype:(((((this.cptratedstars)*(this.cptratedby))-(this.cucprating))/(this.cptratedby - 1))>=4) ? '4★ & above' :
  ((((((this.cptratedstars)*(this.cptratedby))-(this.cucprating))/(this.cptratedby - 1))>=3) ? '3★ & above' : 
  ((((((this.cptratedstars)*(this.cptratedby))-(this.cucprating))/(this.cptratedby - 1))>=2) ? '2★ & above' : 
  ((((((this.cptratedstars)*(this.cptratedby))-(this.cucprating))/(this.cptratedby - 1))>=1) ? '1★ & above' : (''))))
},{merge:true}).then(()=>{
  this.afs.collection('users').doc(this.currentcustid).set({
    ratedto:firebase.firestore.FieldValue.arrayRemove(prdid)
  },{merge:true})

});
}).then(()=>{
  this.afs.collection('Ratingsreviews',ref=>ref.where('uid','==',this.currentcustid).where('pid','==',prdid)).get().toPromise().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
  });
})

}

//-------------------R&R END-------------------
removewishlist(w){
  this.loadwishlistproducts=this.loadwishlistproducts.filter(item => item !== w)
  this.currentcustid=firebase.auth().currentUser.uid;
this.afs.collection('Products').doc(w.KEYID).set({
  wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
},{merge:true}).then(()=>{
  this.afs.collection('users').doc(this.currentcustid).set({
    wishlist:firebase.firestore.FieldValue.arrayRemove(w.KEYID)
  },{merge:true})
})
}
productclicked(prdid){
  this.router.navigate(
    ['productdescription'], 
    {
      queryParams: {myprParam: JSON.stringify(prdid) } ,
      // queryParamsHandling: 'merge'
    });
}

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if(this.prevButtonTrigger && this.prevButtonTrigger != trigger){
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    })
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100)
  }

  signout(){
    firebase.auth().signOut().then(()=>{
      this.router.navigate(
        ['sign-in']);
    })
  }
}
