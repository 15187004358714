import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, timestamp } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import Drift from 'drift-zoom';
import { Slick } from 'ngx-slickjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Globals} from '../../assets/shared/globals'

declare var Razorpay: any; 

@Component({
  selector: 'app-productdescription',
  templateUrl: './productdescription.component.html',
  styleUrls: ['./productdescription.component.css']
})

export class ProductdescriptionComponent implements OnInit {
  brcollection: any;
  newarray: any[]=[];
  currentselectedcode: any;
  clickedprdid: any;
  
  currentcustid: string;
  prdstars: any;
  noofratings: any;
  
  currentselectedprdkeyid: number;

  currentselectedcolor: any;
  currentselectedsize: any;
  currentprdkeyid: any;
  currentselectedbcc: any;
  currentonlinestock: any;
  currenthoveredimage: number =0;
  recentlyviewedlength: number =0;
  oldviewedprd: string ='';
  loadrecentlyviewed: any[];
  loadrecentlyviewedproducts: any[];
  ratingsreviewsary: any;
  ratingsreviewsarylength: any;
  similarcatproduct: any[]=[];
  suggestedforyou: any[]=[];
  addorreplace(array, item){
    const i=array.findIndex(_item => _item.KEYID===item.KEYID);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

  addorreplacesize(array, item){
    const i=array.findIndex(_item => _item.size===item.size);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplacecolor(array, item){
    const i=array.findIndex(_item => _item.color===item.color);
    if(i>-1) array[i] =item;
    else array.push(item);
   }


   showshare: boolean =false; 
   subshareentered: boolean =false;

  sharemouseentercall(){
    this.showshare=true;
}
sharemouseleavecall(){
  if(this.subshareentered==false){
    this.showshare=false;
}
}
subsharemouseentercall(){
  this.subshareentered=true;
  this.showshare=true;
}
subsharemouseleavecall(){
  this.subshareentered=false;

  this.showshare=false;
}

  constructor(public snackBar: MatSnackBar, public globals: Globals, public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private afs: AngularFirestore, private changeDetection: ChangeDetectorRef, public router: Router) {
 
this.afAuth.authState.subscribe(user=>{
  if(user){
  this.currentcustid=firebase.auth().currentUser.uid;
  }
})

   }
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }
   config: Slick.Config = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 5,
    //variableWidth: true,
    // draggable: false,
    // rows:1,
    // swipeToSlide: false,
    // swipe:false,
    // touchMove: false,
    mouseWheelMove: false,
    // accessibility: true,
    // variableWidth: false,
    // focusOnSelect: false,
    // centerMode: false,
        arrows: true,
        prevArrow:"<div class='material-icons slickleftarrow'>keyboard_arrow_left</div>",
        nextArrow:"<div class='material-icons slickrightarrow'>keyboard_arrow_right</div>",
    
        //mobileFirst: true, //optional, to be used only if your page is mobile first
        // responsive: [ {
        //   breakpoint: 786,
        //     settings: {
        //               slidesToShow: 3,
        //               slidesToScroll: 1,
        //               }
        //  },
        //  {
        //   breakpoint: 568,
        //     settings: {
        //               slidesToShow: 2,
        //               slidesToScroll: 1
        //             }
        //   }]
    }



   selectedproduct: any[]=[];
   groupproducts: any[]=[];

   sizearray:any[]=[];
   colorarray:any[]=[];

   messageCP: string;


     bigimage:any;
  prdcode:any;



  imagelisthover(i,j){
    this.bigimage=this.groupproducts[i].images[j];
    
    this.prdcode = this.groupproducts[i].code;

    this.currenthoveredimage=j
    
      }



  ngOnInit() {

        // this.activatedRoute.params.subscribe(params => {
        //   this.clickedprdid = params['productid'];
        //   });
 
             this.route.queryParamMap.subscribe((params: any) => {
      this.clickedprdid = String(JSON.parse(params.params.myprParam));
      console.log(this.clickedprdid)
      });  



    // this.afAuth.authState.subscribe(user=>{
    //   if(user)
    //   this.currentcustid=firebase.auth().currentUser.uid;
    //   this.recentlyviewedlength=0;
    //   this.loadrecentlyviewed =[];

    //   this.afs.collection("users", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
    //     if (!querySnapshot.empty) {
    //       querySnapshot.forEach(doc => {
    //         this.recentlyviewedlength=doc.data().recentlyviewed ? doc.data().recentlyviewed.length : 0
    //         this.oldviewedprd=doc.data().recentlyviewed ? doc.data().recentlyviewed[0] : ''
    //         this.loadrecentlyviewed=doc.data().recentlyviewed
    //       })
    //     }
    //   }).then(()=>{
    //     console.log(this.recentlyviewedlength)

    //     if(this.recentlyviewedlength >= 25){
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       recentlyviewed:firebase.firestore.FieldValue.arrayRemove(this.oldviewedprd)
    //     },{merge:true})
    //   }
    //       this.afs.collection('users').doc(this.currentcustid).set({
    //       recentlyviewed:firebase.firestore.FieldValue.arrayUnion(this.clickedprdid)
    //     },{merge:true})
      
    //   }).then(()=>{
    //     this.loadrecentlyviewedproducts =[];
    //     for(let i=0;i < this.loadrecentlyviewed.length;i++){
    //     this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadrecentlyviewed[i] )).get().toPromise().then(querySnapshot => {
    //       if (!querySnapshot.empty) {
    //         querySnapshot.forEach(doc => {
    //           this.loadrecentlyviewedproducts.push({'KEYID':doc.data().KEYID,
    //           'bcc':doc.data().bcc,
    //           'brand':doc.data().brand,
    //           'category':doc.data().category,
    //           'code':doc.data().code,    
    //           'rate':doc.data().rate,             
    //           'onlinerate':doc.data().onlinerate,                                 
    //           'images':doc.data().images,
    //           'description':doc.data().description,
    //           'highlights':doc.data().highlights,
    //           'color':doc.data().color,
    //          'size':doc.data().size,
    //          'sizechart':doc.data().sizechart,
    //          'wishlist':doc.data().wishlist!=undefined ? (doc.data().wishlist.includes(this.currentcustid) ? true : false) : false,
    //          'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
    //          'totalstars':doc.data().totalstars ? doc.data().totalstars: 0 ,
    //         })
    //         })
    //       }
    //   })
    //     }
    //   })
    //   })




 this.afs.collection('Products',ref => ref.where('KEYID', '==', this.clickedprdid))
 .get().toPromise()
 .then(querySnapshot => {
     querySnapshot.forEach(doc => {
       this.selectedproduct.push({'KEYID':doc.data().KEYID,
                           'bcc':doc.data().bcc,
                           'brand':doc.data().brand,
                           'category':doc.data().category,
                           'code':doc.data().code, 
                           'onlinerate':doc.data().onlinerate,                                    
                           'images':doc.data().images,
                           'description':doc.data().description,
                           'highlights':doc.data().highlights,
                           'color':doc.data().color,
                          'size':doc.data().size,
                          'sports':doc.data().sports,
                          'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                          'totalstars':doc.data().totalstars ? doc.data().totalstars: 0,
                          'onlinestock':(doc.data().onlinestock == 'qntyshop1') 
                          ? doc.data().qntyshop1 :((doc.data().onlinestock == 'qntyshop2') 
                          ? doc.data().qntyshop2 :((doc.data().onlinestock == 'qntygodown') 
                          ? doc.data().qntyshop1+doc.data().qntyshop2+doc.data().qntygodown : ((doc.data().onlinestock == 'total') 
                          ? doc.data().qntygodown : (doc.data().onlinestock == 'unlimited') 
                          ? 'unlimited' : 'Unavailable'))),
                        });
                        this.currentonlinestock=(doc.data().onlinestock == 'qntyshop1') 
                        ? doc.data().qntyshop1 :((doc.data().onlinestock == 'qntyshop2') 
                        ? doc.data().qntyshop2 :((doc.data().onlinestock == 'qntygodown') 
                        ? doc.data().qntyshop1+doc.data().qntyshop2+doc.data().qntygodown : ((doc.data().onlinestock == 'total') 
                        ? doc.data().qntygodown : (doc.data().onlinestock == 'unlimited') 
                        ? 'unlimited' : 'Unavailable'))),
                          this.currentprdkeyid=doc.data().KEYID;
                          this.currentselectedbcc=doc.data().bcc;
                          this.currentselectedcolor=doc.data().color;
                          this.currentselectedsize=doc.data().size;
                          this.currentselectedcode=doc.data().code;
     });
 })

 .catch(function(error) {
     console.log("Error getting documents: ", error);
 })
 .then(()=>{

  //this.bigimage=this.selectedproduct[0].images[0];

  this.brcollection = this.afs.collection('Products',ref => 
  ref.where('brand', '==', this.selectedproduct[0].brand)
  .where('category', '==', this.selectedproduct[0].category)
  ).snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.brcollection.subscribe((posts) => {

  this.afs.collection('Products',ref => 
  ref.where('brand', '==', this.selectedproduct[0].brand)
  .where('category', '==', this.selectedproduct[0].category)
  ).get().toPromise()
  .then(querySnapshot => {
    this.groupproducts=[];
    this.colorarray=[];
    this.sizearray=[];
      querySnapshot.forEach(doc => {
        this.groupproducts.push({'KEYID':doc.data().KEYID,
                            'bcc':doc.data().bcc,
                            'brand':doc.data().brand,
                            'category':doc.data().category,
                            'code':doc.data().code,    
                            'rate':doc.data().rate,             
                            'onlinerate':doc.data().onlinerate,                                 
                            'images':doc.data().images,
                            'description':doc.data().description,
                            'highlights':doc.data().highlights,
                            'color':doc.data().color,
                           'size':doc.data().size,
                           'sizechart':doc.data().sizechart,
                           'wishlist':doc.data().wishlist!=undefined ? (doc.data().wishlist.includes(this.currentcustid) ? true : false) : false,
                           'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                           'totalstars':doc.data().totalstars ? doc.data().totalstars: 0 ,
                           'onlinestock':(doc.data().onlinestock == 'qntyshop1') 
                           ? doc.data().qntyshop1 :((doc.data().onlinestock == 'qntyshop2') 
                           ? doc.data().qntyshop2 :((doc.data().onlinestock == 'qntygodown') 
                           ? doc.data().qntyshop1+doc.data().qntyshop2+doc.data().qntygodown : ((doc.data().onlinestock == 'total') 
                           ? doc.data().qntygodown : (doc.data().onlinestock == 'unlimited') 
                           ? 'unlimited' : 'Unavailable'))),
                            'colorvisible':true,
                            'sizevisible':true});

                            this.addorreplacecolor(this.colorarray,{'KEYID':doc.data().KEYID,'size':doc.data().size,'color':doc.data().color,'colorvisible':true,'sizevisible':true})
                            this.addorreplacesize(this.sizearray,{'KEYID':doc.data().KEYID,'size':doc.data().size,'color':doc.data().color,'colorvisible':true,'sizevisible':true})

        
      });
  }).then(()=>{
    console.log(this.groupproducts)
    this.checkprdexistence();
    this.changebigimage();
    this.changeratingsandreviews();
  })
  })
 }).then(()=>{
  this.afAuth.authState.subscribe(user=>{
    if(user){
    this.currentcustid=firebase.auth().currentUser.uid;
    this.recentlyviewedlength=0;
    this.loadrecentlyviewed =[];

    this.afs.collection("users", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.recentlyviewedlength=doc.data().recentlyviewed ? doc.data().recentlyviewed.length : 0
          this.oldviewedprd=doc.data().recentlyviewed ? doc.data().recentlyviewed[0] : ''
          this.loadrecentlyviewed=doc.data().recentlyviewed
        })
      }
    }).then(()=>{
      console.log(this.recentlyviewedlength)

      if(this.recentlyviewedlength >= 25){
      this.afs.collection('users').doc(this.currentcustid).set({
        recentlyviewed:firebase.firestore.FieldValue.arrayRemove(this.oldviewedprd)
      },{merge:true})
    }
        this.afs.collection('users').doc(this.currentcustid).set({
        recentlyviewed:firebase.firestore.FieldValue.arrayUnion(this.clickedprdid)
      },{merge:true})
    
    }).then(()=>{
      this.loadrecentlyviewedproducts =[];
      for(let i=0;i < this.loadrecentlyviewed.length;i++){
      this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadrecentlyviewed[i]).limit(10)).get().toPromise().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            this.loadrecentlyviewedproducts.push({'KEYID':doc.data().KEYID,
            'bcc':doc.data().bcc,
            'brand':doc.data().brand,
            'category':doc.data().category,
            'code':doc.data().code,    
            'rate':doc.data().rate,             
            'onlinerate':doc.data().onlinerate,                                 
            'images':doc.data().images,
            'description':doc.data().description,
            'highlights':doc.data().highlights,
            'color':doc.data().color,
           'size':doc.data().size,
           'sizechart':doc.data().sizechart,
           'wishlist':doc.data().wishlist!=undefined ? (doc.data().wishlist.includes(this.currentcustid) ? true : false) : false,
           'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
           'totalstars':doc.data().totalstars ? doc.data().totalstars: 0 ,
          })
          })
        }
    })
      }
    })
  }
    })
  
 }).then(()=>{ this.afs.collection('Products',ref => 
 ref
//  .where('brand', '==', this.selectedproduct[0].brand)
 .where('category', '==', this.selectedproduct[0].category).limit(10)
 ).get().toPromise()
 .then(querySnapshot => {
   this.similarcatproduct=[];
     querySnapshot.forEach(doc => {
       this.similarcatproduct.push({'KEYID':doc.data().KEYID,
                           'bcc':doc.data().bcc,
                           'brand':doc.data().brand,
                           'category':doc.data().category,
                           'code':doc.data().code,    
                           'rate':doc.data().rate,             
                           'onlinerate':doc.data().onlinerate,                                 
                           'images':doc.data().images,
                           'description':doc.data().description,
                           'highlights':doc.data().highlights,
                           'color':doc.data().color,
                          'size':doc.data().size,
                          'sizechart':doc.data().sizechart,
                          'wishlist':doc.data().wishlist!=undefined ? (doc.data().wishlist.includes(this.currentcustid) ? true : false) : false,
                          'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                          'totalstars':doc.data().totalstars ? doc.data().totalstars: 0
                        });

                      }) 
     });
 }).then(()=>{ this.afs.collection('Products',ref => 
 ref
 .where('sports', '==', this.selectedproduct[0].sports).limit(10)
 ).get().toPromise()
 .then(querySnapshot => {
   this.suggestedforyou=[];
     querySnapshot.forEach(doc => {
       this.suggestedforyou.push({'KEYID':doc.data().KEYID,
                           'bcc':doc.data().bcc,
                           'brand':doc.data().brand,
                           'category':doc.data().category,
                           'code':doc.data().code,    
                           'rate':doc.data().rate,             
                           'onlinerate':doc.data().onlinerate,                                 
                           'images':doc.data().images,
                           'description':doc.data().description,
                           'highlights':doc.data().highlights,
                           'color':doc.data().color,
                          'size':doc.data().size,
                          'sizechart':doc.data().sizechart,
                          'wishlist':doc.data().wishlist!=undefined ? (doc.data().wishlist.includes(this.currentcustid) ? true : false) : false,
                          'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                          'totalstars':doc.data().totalstars ? doc.data().totalstars: 0
                        });

                      }) 
     });
 })
}
colorbtnclicked(c,s){
  this.currentselectedcolor=c;

  for (var i=0; i<this.sizearray.length; i++) {
    this.colorarray[i].colorvisible = true;
    this.colorarray[i].sizevisible = true;
    this.sizearray[i].colorvisible = true;
    this.sizearray[i].sizevisible = true;

    if (this.sizearray[i].color != c && this.sizearray[i].size != s) {
      this.sizearray[i].sizevisible = false;
    }

  }
  let yeses = []
  this.groupproducts.forEach((ele,index)=> ele.size===this.currentselectedsize ? yeses.push(index) : '')
  for (var i=0; i<yeses.length; i++) {
    this.groupproducts[i].sizevisible = true;
    this.groupproducts[i].colorvisible = true;
  }
  let noes = []
  this.groupproducts.forEach((ele,index)=> ele.color===c &&  ele.size !== s ? noes.push(index) : '')
  for (var i=0; i<noes.length; i++) {
    this.groupproducts[i].sizevisible = false;
  }

if(  this.groupproducts.findIndex(item=>item.color === this.currentselectedcolor
  && item.size === this.currentselectedsize) !==-1){
  this.currentprdkeyid=this.groupproducts[this.groupproducts.findIndex(
    item=>item.color === this.currentselectedcolor
       && item.size === this.currentselectedsize
     )].KEYID

     this.router.navigate(
      [], 
      {
        queryParams: {myprParam: JSON.stringify(this.currentprdkeyid) } ,
        queryParamsHandling: 'merge'
      });
}
else{
  this.currentprdkeyid="null"
}
this.checkprdexistence();
this.changebigimage();
this.changeratingsandreviews();
}
sizebtnclicked(s,c){
  this.currentselectedsize=s;
  
    for (var i=0; i<this.colorarray.length; i++) {
      this.colorarray[i].colorvisible = true;
      this.colorarray[i].sizevisible = true;
      this.sizearray[i].colorvisible = true;
      this.sizearray[i].sizevisible = true;

    if (this.colorarray[i].size != s && this.colorarray[i].color != c) {
      this.colorarray[i].colorvisible = false;
    }
  }

  let yeses = []
  this.groupproducts.forEach((ele,index)=> ele.color===this.currentselectedcolor ? yeses.push(index) : '')
  for (var i=0; i<yeses.length; i++) {
    this.groupproducts[i].sizevisible = true;
    this.groupproducts[i].colorvisible = true;
  }
  let noes = []
  this.groupproducts.forEach((ele,index)=> ele.color===c &&  ele.size !== s ? noes.push(index) : '')
  for (var i=0; i<noes.length; i++) {
    this.groupproducts[i].colorvisible = false;
  }
  
if(  this.groupproducts.findIndex(item=>item.color === this.currentselectedcolor
  && item.size === this.currentselectedsize) !==-1){
  this.currentprdkeyid=this.groupproducts[this.groupproducts.findIndex(
    item=>item.color === this.currentselectedcolor
       && item.size === this.currentselectedsize
     )].KEYID

     this.router.navigate(
      [], 
      {
        queryParams: {myprParam: JSON.stringify(this.currentprdkeyid) } ,
        queryParamsHandling: 'merge'
      });
}
else{
  this.currentprdkeyid="null"
  this.openSnackBar("Product either Unavailable or Out Of Stock","OK");
}
this.checkprdexistence();
this.changebigimage();
this.changeratingsandreviews();
}

checkprdexistence(){
  this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid);
}

changebigimage(){

    this.bigimage = this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].images[0];
    this.changeDetection.detectChanges();
    this.currenthoveredimage=0;
    this.calldrift(this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid))
    //this.calldriftall();
  }
changeratingsandreviews(){
    this.ratingsreviewsary =[];
    this.ratingsreviewsarylength=0;
    this.ratingsreviewsary=this.afs.collection("Ratingsreviews",ref=> ref.where('pid', '==', this.currentprdkeyid).limit(5)).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => a.payload.doc.data()))
      );
      this.ratingsreviewsary.subscribe(result =>{
        console.log(result.length);
        this.ratingsreviewsarylength=result.length;
      })
}
  updateWishlist(o){

    this.afAuth.authState.subscribe(user=>{
      if(user){
    this.currentcustid=firebase.auth().currentUser.uid;

    console.log(o.code,this.currentcustid)
    if(o.wishlist==false){
      this.afs.collection('Products').doc(o.KEYID).set({
        wishlist:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
      },{merge:true}).then(()=>{
        this.afs.collection('users').doc(this.currentcustid).set({
          wishlist:firebase.firestore.FieldValue.arrayUnion(o.KEYID)
        },{merge:true})
      })
      //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=true;
    }
    if(o.wishlist==true){
      this.afs.collection('Products').doc(o.KEYID).set({
        wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
      },{merge:true}).then(()=>{
        this.afs.collection('users').doc(this.currentcustid).set({
          wishlist:firebase.firestore.FieldValue.arrayRemove(o.KEYID)
        },{merge:true})
      })
      //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=false;
    }
      }
      else{
        this.router.navigate(
          ['sign-in'], 
          {
            queryParams: { redirectURL: this.router.url } 
          });
      }
      })

    // this.currentcustid=firebase.auth().currentUser.uid;

    // console.log(o.code,this.currentcustid)
    // if(o.wishlist==false){
    //   this.afs.collection('Products').doc(o.KEYID).set({
    //     wishlist:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
    //   },{merge:true}).then(()=>{
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       wishlist:firebase.firestore.FieldValue.arrayUnion(o.KEYID)
    //     },{merge:true})
    //   })
    //   //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=true;
    // }
    // if(o.wishlist==true){
    //   this.afs.collection('Products').doc(o.KEYID).set({
    //     wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
    //   },{merge:true}).then(()=>{
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       wishlist:firebase.firestore.FieldValue.arrayRemove(o.KEYID)
    //     },{merge:true})
    //   })
    //   //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=false;
    // }
  }


// options = {
//   "key": "rzp_test_vmkyCOWphmctuL", // Enter the Key ID generated from the Dashboard
//   "amount": this.currentprdonlinerate, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
//   "currency": "INR",
//   "name": "Acme Corp",
//   "description": "Test Transaction",
//   "image": "https://example.com/your_logo",
//   // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
//   "handler": function (response){
//       alert(response.razorpay_payment_id);
//       alert(response.razorpay_order_id);
//       alert(response.razorpay_signature)
//   },
//   "prefill": {
//       "name": "Gaurav Kumar",
//       "email": "gaurav.kumar@example.com",
//       "contact": "9999999999"
//   },
//   "notes": {
//       "address": "Corporate Office"
//   },
//   "theme": {
//       "color": "#3399cc"
//   }
// };

rzpl;
pay(){
  this.rzpl=new Razorpay(
    {
      "key": "rzp_test_vmkyCOWphmctuL", // Enter the Key ID generated from the Dashboard
      "amount": (this.selectedproduct[0].onlinerate)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Acme Corp",
      "description": "Test Transaction",
      "image": "https://example.com/your_logo",
      // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (response){
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);

          // this.afs.collection('Orders').doc(this.date2).set(
          //   {
          //     routebillid: this.routinebillid + 1,
          //   },
          //   { merge: true }
          // )
        

      },
      "prefill": {
          "name": "Gaurav Kumar",
          "email": "gaurav.kumar@example.com",
          "contact": "9999999999"
      },
      "notes": {
          "address": "Corporate Office"
      },
      "theme": {
          "color": "#3399cc"
      }
    }
  );
  this.rzpl.open();
}

addtocart(){
  this.afAuth.authState.subscribe(user=>{
    if(user){
    this.currentcustid=firebase.auth().currentUser.uid;

  this.afs.collection('Products').doc(this.currentprdkeyid.toString()).set({
    cartby:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
  },{merge:true})
  .then(()=>{
    this.afs.collection('users').doc(this.currentcustid).set({
      cartto:firebase.firestore.FieldValue.arrayUnion(this.currentprdkeyid)
    },{merge:true})
  })
  .then(()=>{
    this.afs.collection("Carts", ref=> ref.where("pid", "==",this.currentprdkeyid ).where("uid", "==",this.currentcustid)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.empty) {
    let ids =this.afs.createId();
    this.afs.collection('Carts').doc(ids).set({
        'iscart':true,
        qnty:1,
        'uid':this.currentcustid.toString(),
        'pid':this.currentprdkeyid.toString(),
        'cid':ids,
    },{merge:true}).then(()=>{
      console.log("added to cart")
      this.openSnackBar("Product added to cart","OK");
    })
  }
  else if(!querySnapshot.empty){
    console.log("Already in cart")
    this.openSnackBar("Product already in your cart","OK");

  }
  })
})
    }
else{
  this.router.navigate(
    ['sign-in'], 
    {
      queryParams: { redirectURL: this.router.url } 
    });
}
  })
}

buynow(prdid){
  this.afAuth.authState.subscribe(user=>{
    if(user){
    this.currentcustid=firebase.auth().currentUser.uid;
  this.router.navigate(
    ['buynow'], 
    {
      queryParams: {myprParam: JSON.stringify(prdid) } ,
      // queryParamsHandling: 'merge'
    });
  }
  else{
    this.router.navigate(
      ['sign-in'], 
      {
        queryParams: { redirectURL: this.router.url } 
      });
  }
})
}


prdColors: { } = {
    'AB': {colorname:'Airforce Blue',colorcode:'#01478C'},
    'AM': {colorname:'Aquamarine',colorcode:'aquamarine'},
    'BK': {colorname:'Black',colorcode:'black'},
    'BR': {colorname:'Brown',colorcode:'brown'},
    'CY': {colorname:'Cyan',colorcode:'cyan'},
    'DG': {colorname:'Dark Gray',colorcode:'#393939'},
    'DN': {colorname:'Dark Green',colorcode:'#024A00'},
    'DP': {colorname:'Dark Pink',colorcode:'#E10463'},
    'FB': {colorname:'Ferrosi Blue',colorcode:'#0B9AF6'},
    'FG': {colorname:'Fluroscent Green',colorcode:'#48FF43'},
    'FO': {colorname:'Fluroscent Orange',colorcode:'#FC8D33'},
    'GY': {colorname:'Golden Yellow',colorcode:'#FFDF00'},
    'HW': {colorname:'Half White',colorcode:'#FFFEFA'},
    'IG': {colorname:'Indigo',colorcode:'indigo'},
    'JG': {colorname:'Jet Green',colorcode:'#01A77F'},
    'LY': {colorname:'Lemon Yellow',colorcode:'#FFF001'},
    'LG': {colorname:'Light Gray',colorcode:'#B7B7B7'},
    'LP': {colorname:'Light Pink',colorcode:'#F8B7D9'},
    'MG': {colorname:'Magenta',colorcode:'magenta'},
    'MN': {colorname:'Maroon',colorcode:'maroon'},
    'MC': {colorname:'Muticolor',colorcode:'linear-gradient(45deg, red, blue) !IMPORTANT'},
    'NB': {colorname:'Navy Blue',colorcode:'navy'},
    'OL': {colorname:'Olive',colorcode:'olive'},
    'OE': {colorname:'Orange',colorcode:'orange'},
    'PG': {colorname:'Parrot Green',colorcode:'#04AC01'},
    'PL': {colorname:'Purple',colorcode:'purple'},
    'RD': {colorname:'Red',colorcode:'red'},
    'RB': {colorname:'Royal Blue',colorcode:'#0016F3'},
    'SN': {colorname:'Sandal',colorcode:'#F4DEB5'},
    'SL': {colorname:'Silver',colorcode:'silver'},
    'SB': {colorname:'Sky Blue',colorcode:'#92C4FF'},
    'TN': {colorname:'Tan',colorcode:'tan'},
    'TL': {colorname:'Teal',colorcode:'teal'},
    'TQ': {colorname:'Turquoise',colorcode:'turquoise'},
    'VL': {colorname:'Violet',colorcode:'violet'},
    'WT': {colorname:'White',colorcode:'white'}
};

getColorByBgColor(bgColor) {
  if (!bgColor) { return ''; }
  return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
}

calldriftall(){

        console.log("Ready!");


        const driftImgs = document.querySelectorAll('.mainimage');
const pane = document.querySelector('.mainzz');
driftImgs.forEach(img => {
	new Drift(img, {
		paneContainer: pane,
    inlinePane: 900,
    inlineOffsetY: -85,
    containInline: true,
    hoverBoundingBox: true,	});
});


}

calldrift(j){
  console.log(j);

  new Drift(document.querySelector('#mainimage'+j), {
    paneContainer: document.querySelector('.mainzz'),
    inlinePane: 900,
    inlineOffsetY: -85,
    containInline: true,
    hoverBoundingBox: true,

});
        console.log("Ready!");

}



ratereviews(prdid){
  this.router.navigate(
    ['ratingsandreviews'], 
    {
      queryParams: {myprParam: JSON.stringify(prdid) } ,
      // queryParamsHandling: 'merge'
    });
}

navigateviewallreviews(prdid){
  this.router.navigate(
    ['allreviews'], 
    {
      queryParams: {myprParam: JSON.stringify(prdid) } ,
      // queryParamsHandling: 'merge'
    });
}

navigateallviewedrecently(){
  this.router.navigate(
    ['allrecentlyviewed']);
}
productclicked(prdid){
  // this.router.navigate(
  //   ['productdescription'], 
  //   {
  //     queryParams: {myprParam: JSON.stringify(prdid) } ,
  //     // queryParamsHandling: 'merge'
  //   }).then(()=>{
  //     window.location.reload();
  //   })
   window.open(window.location.origin+'/productdescription?myprParam="'+prdid+'"', '_blank')
}
}
