import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as firebase from 'firebase/app';

@Component({
  selector: 'change-password',
  templateUrl: 'change-password.html',
})
export class ChangePassworddialog implements OnInit {
    public cpForm: FormGroup;
    currpass: string;
    newpass: string;
    retypenewpass: string;
    entotp: string;

    reCaptchaVerifier!: any;
    verify: any;

    
  hide: boolean = true;

  visibFunction() {
    this.hide = !this.hide;
  }

  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor( private fb: FormBuilder,
    public snackBar: MatSnackBar,  
    public afAuth: AngularFireAuth,
    
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ChangePassworddialog>) {
      if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }

  Form() {
    this.cpForm = this.fb.group({
        currentpassword: ['',[Validators.required, Validators.minLength(6)]],
        newpassword: ['',[Validators.required, Validators.minLength(6)]],
        // retypenewpassword: ['',[Validators.required, Validators.minLength(3)]],
        enterotp: [''],
    })
  }
  get currentpassword() {
    return this.cpForm.get('currentpassword');
  }
  get newpassword() {
    return this.cpForm.get('newpassword');
  }
//   get retypenewpassword() {
//     return this.cpForm.get('retypenewpassword');
//   }
  get enterotp() {
    return this.cpForm.get('enterotp');
  }

    ngOnInit() {
        this.Form();
        this.currpass='';
        this.newpass='';
        this.retypenewpass='';
        this.entotp='';

        this.getOTP();
    }


    getOTP() {
        this.afAuth.authState.subscribe(user=>{
            if(user){
        //     this.currentcustid=firebase.auth().currentUser.uid;
        // var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
        // //if phone number
        // if(phoneRegex.test(this.usermobile)){
    
        this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'sign-in-button',
          {
            size: 'invisible',
          }
        );
        console.log(this.reCaptchaVerifier);
    
        firebase
          .auth()
          .signInWithPhoneNumber(firebase.auth().currentUser.phoneNumber, this.reCaptchaVerifier)
          .then((confirmationResult) => {
            localStorage.setItem(
              'verificationId',
              JSON.stringify(confirmationResult.verificationId)
            );
            // this.ngZone.run(() => {
            //   this.router.navigate(['/code']);
            // });
          })
          .catch((error) => {
            // console.log(error.message);
            // alert(error.message);
            this.openSnackBar(error.message,"OK");
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          
        // }
        // else{
        //   this.usermobilefoc.nativeElement.focus();
        //   this.openSnackBar("Please enter valid Mobile number","OK");
        // }
    }
})
      }

      changepasswordfunc(){
        if(this.newpass!='' && this.entotp!=''){
              if(this.newpass.length>=6){

                this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
      
          var credential = firebase.auth.PhoneAuthProvider.credential(
            this.verify,
            this.entotp
          );
      
           console.log(credential);
      
          firebase.auth().signInWithCredential(credential).then((response) => {
              console.log(response);
              localStorage.setItem('user_data', JSON.stringify(response));
      
              const user = firebase.auth().currentUser;
              const useremail = firebase.auth().currentUser.email;

      user.updateEmail(useremail).then(() => {
        user.updatePassword(this.newpass).then(() => {
          console.log("passwordchangesscuccessfully")
        })
      })
      
      
            })
            .catch((error) => {
              // console.log(error);
              // alert(error.message);
              this.openSnackBar(error.message,"OK");
            });
          }else{
            this.openSnackBar("Password must be atleast 6 characters","OK");
          }
          
        }else{
          this.openSnackBar("Form Invalid","OK");
        }
    }

// changeemailfunc(){
//     if(this.entotp!='' && this.newpass!='' && this.newpass!='' && this.entotp!=''){
//         var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;
//         if(emailRegex.test(this.emailinput)){
//           if(this.passwordinput.length>=6){
//         console.log(this.otpinput)
//       this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
  
//       var credential = firebase.auth.PhoneAuthProvider.credential(
//         this.verify,
//         this.otpinput
//       );
  
//        console.log(credential);
  
//       // this.SignUpEmailPass(this.emailinput,this.passwordinput)
//       firebase
//         .auth()
//         .signInWithCredential(credential)
//         .then((response) => {
//           console.log(response);
//           localStorage.setItem('user_data', JSON.stringify(response));
//           //this.SignUpEmailPass(this.emailinput,this.passwordinput)
  
//           const user = firebase.auth().currentUser;
    
//   user.updateEmail(this.emailinput).then(() => {
//     user.updatePassword(this.passwordinput).then(() => {
//       this.SetUserData(user);
//     })
//   })
  
  
  
//           this.ngZone.run(() => {
//             this.router.navigate(['/dashboard']);
//           });
//         })
//         .catch((error) => {
//           // console.log(error);
//           // alert(error.message);
//           this.openSnackBar(error.message,"OK");
//         });
//       }else{
//         this.openSnackBar("Password must be atleast 6 characters","OK");
//       }
//       }else{
//         this.openSnackBar("Invalid Email","OK");
//       }
//     }else{
//       this.openSnackBar("Form Invalid","OK");
//     }
// }



  onConfirmClick(): void {
      this.changepasswordfunc();
   // this.dialogRef.close(true);
  }

  
}