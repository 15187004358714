import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component';

import { AuthGuard } from "../../shared/guard/auth.guard";
import { SecureInnerPagesGuard } from '../../auth/guards/secure-inner-pages-guard-guard.guard';
import { HomeComponent } from 'src/app/home/home.component';

import { ProductcategoryComponent } from '../../productcategory/productcategory.component';
import { ProductdescriptionComponent } from '../../productdescription/productdescription.component';
import { ProductfilterComponent } from '../../productfilter/productfilter.component';
import { MyprofileComponent } from 'src/app/myprofile/myprofile.component';
import { RatingsandreviewsComponent } from 'src/app/ratingsandreviews/ratingsandreviews.component';
import { CartComponent } from 'src/app/cart/cart.component';
import { CheckoutComponent } from 'src/app/checkout/checkout.component';
import { OrderdetailsComponent } from 'src/app/orderdetails/orderdetails.component';
import { WishlistComponent } from 'src/app/wishlist/wishlist.component';
import { AllreviewsComponent } from 'src/app/allreviews/allreviews.component';
import { AllrecentlyviewedComponent } from 'src/app/allrecentlyviewed/allrecentlyviewed.component';
import { MyordersComponent } from 'src/app/myorders/myorders.component';
import { BuynowComponent } from 'src/app/buynow/buynow.component';

const routes: Routes = [
  // { path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: '', redirectTo: '/home', pathMatch: 'full'},



  { path: 'productcategory', component: ProductcategoryComponent }, 
  { path: 'productfilter', component: ProductfilterComponent }, 
  { path: 'productdescription', component: ProductdescriptionComponent },

  { path: 'myprofile', component: MyprofileComponent },
  { path: 'ratingsandreviews', component: RatingsandreviewsComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'orderdetails', component: OrderdetailsComponent },
  { path: 'wishlist', component: WishlistComponent },
  { path: 'allreviews', component: AllreviewsComponent },
  { path: 'allrecentlyviewed', component: AllrecentlyviewedComponent },
  { path: 'myorders', component: MyordersComponent },
  { path: 'buynow', component: BuynowComponent },

  { path: 'home', component: HomeComponent },
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'sign-up', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },

  { path: '**', redirectTo: '/home'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
