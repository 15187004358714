import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as firebase from 'firebase/app';

@Component({
  selector: 'change-mobile',
  templateUrl: 'change-mobile.html',
})
export class ChangeMobiledialog implements OnInit {
    public cmForm: FormGroup;
    currpass: string;
    newmobile: string;
    entotp: string;

    reCaptchaVerifier!: any;
    verify: any;

    
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor( private fb: FormBuilder, private afs: AngularFirestore, 
    public snackBar: MatSnackBar,  
    public afAuth: AngularFireAuth,
    
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ChangeMobiledialog>) {
      if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }

  Form() {
    this.cmForm = this.fb.group({
        currentpassword: ['',[Validators.required, Validators.minLength(3)]],
        newmobileid: ['',[Validators.required, Validators.minLength(3)]],
        enterotp: ['',[Validators.required, Validators.minLength(3)]],
    })
  }
  get currentpassword() {
    return this.cmForm.get('currentpassword');
  }
  get newmobileid() {
    return this.cmForm.get('newmobileid');
  }
  get enterotp() {
    return this.cmForm.get('enterotp');
  }

    ngOnInit() {
        this.Form();
        this.currpass='';
        this.newmobile='';
        this.entotp='';

        this.getOTP();
    }


    getOTP() {
        this.afAuth.authState.subscribe(user=>{
            if(user){
        //     this.currentcustid=firebase.auth().currentUser.uid;
        // var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
        // //if phone number
        // if(phoneRegex.test(this.usermobile)){
    
        this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'sign-in-button',
          {
            size: 'invisible',
          }
        );
        console.log(this.reCaptchaVerifier);
    
        firebase
          .auth()
          .signInWithPhoneNumber(firebase.auth().currentUser.phoneNumber, this.reCaptchaVerifier)
          .then((confirmationResult) => {
            localStorage.setItem(
              'verificationId',
              JSON.stringify(confirmationResult.verificationId)
            );
            // this.ngZone.run(() => {
            //   this.router.navigate(['/code']);
            // });
          })
          .catch((error) => {
            // console.log(error.message);
            // alert(error.message);
            this.openSnackBar(error.message,"OK");
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          
        // }
        // else{
        //   this.usermobilefoc.nativeElement.focus();
        //   this.openSnackBar("Please enter valid Mobile number","OK");
        // }
    }
})
      }


      changemobilefunc(){

          this.openSnackBar("In demo mode, you cant use this service","OK");
        
    }

    
// changemobilefunc(){
//     if(this.entotp!='' && this.newmobile!=''){
//         var mobileRegex = /^(\+91-|\+91|0)?\d{10}$/;
//         if(mobileRegex.test(this.newmobile)){
//           if(this.currpass.length>=6){
//         console.log(this.entotp)
//       this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
  
//       var credential = firebase.auth.PhoneAuthProvider.credential(
//         this.verify,
//         this.entotp
//       );
  
//        console.log(credential);
  
//       // this.SignUpMobilePass(this.mobileinput,this.passwordinput)
//       firebase
//         .auth()
//         .signInWithCredential(credential)
//         .then((response) => {
//           console.log(response);
//           localStorage.setItem('user_data', JSON.stringify(response));
//           //this.SignUpMobilePass(this.mobileinput,this.passwordinput)
  
//           const user = firebase.auth().currentUser;
    
//   user.updateEmail(user.email).then(() => {
//     user.updatePhoneNumber(this.currpass).then(() => {
      
//         this.afs.collection("users").doc(user.uid).update({
//             phone: this.newmobile
//         }).then(()=>{
//             this.openSnackBar('Mobile number updated successfully', 'OK')
//         });
//     })
//   })
  
//         })
//         .catch((error) => {
//           // console.log(error);
//           // alert(error.message);
//           this.openSnackBar(error.message,"OK");
//         });
//       }else{
//         this.openSnackBar("Password must be atleast 6 characters","OK");
//       }
//       }else{
//         this.openSnackBar("Invalid Mobile Number","OK");
//       }
//     }else{
//       this.openSnackBar("Form Invalid","OK");
//     }
// }



  onConfirmClick(): void {
      this.changemobilefunc();
   // this.dialogRef.close(true);
  }

  
}